<template>
  <div class="contents userInfo review">
    <div class="title flexB">
      <h2>리뷰 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">카테고리</p>
          <span
            >{{
              category == "weddingPlace"
                ? "웨딩 장소"
                : category == "collection"
                ? "웨딩 컬렉션"
                : category == "planner"
                ? "플래너"
                : category == "store"
                ? "스토어"
                : ""
            }}
          </span>
        </div>
        <div class="flexL">
          <p class="bold">작성자</p>
          <span>{{ author.username }}</span>
        </div>
        <div class="flexL">
          <p class="bold">작성일</p>
          <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
        <div class="flexL double">
          <p class="bold">리뷰 내용</p>
          <pre>{{ comment }}</pre>
        </div>
        <div class="flexL double" v-if="images.length > 0">
          <p class="bold">리뷰이미지</p>
          <div class="flex reviewImages" style="width: 100%">
            <div v-for="(image, i) in images" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
            </div>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/reviewmanage">목록</router-link>
        <button class="basic" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchReviewDetail, deleteReview } from "@/api/admin";
export default {
  data() {
    return {
      moment: moment,
      author: "",
      eventName: "",
      category: "",
      comment: "",
      thumbnail: "",
      file: "",
      images: [],
      reviewId: "",
      updatedAt: "",
      createdAt: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "11");
  },
  mounted() {
    if (this.$route.query.id) {
      this.reviewId = this.$route.query.id;
      this.getReview();
    }
  },
  methods: {
    getReview() {
      fetchReviewDetail(this.reviewId).then((res) => {
        if (res.data.status == 200) {
          this.author = res.data.data.author;
          this.category = res.data.data.category;
          this.comment = res.data.data.comment;
          this.images = res.data.data.images;
          this.thumbnail = res.data.data.thumbnail;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteReview(this.reviewId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "리뷰 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/reviewmanage");
          }
        });
      });
    },
  },
};
</script>
